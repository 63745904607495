const Config = {
  get environment() {
    return "production";//"test";//"production";
  },
  get baseApiUrl() {
    return "https://a2zmillwork-backend.caprover.a2zwebsolution.com";
  },
  get apiUrl() {
    return Config.baseApiUrl + "/api/v1.0";
  },
  get assetUrl() {
    if (this.environment === "test") {
      return "/images/";
    } else {
      return "https://a2zmillworkllc.s3.amazonaws.com/app-assets/"
    }
  },
  get thumborUrl() {
    return "https://thumbor.caprover.a2zwebsolution.com/unsafe/";
  },
};

export default Config;
