import { lazy } from "react";
const Home = lazy(() => import('./home'));
const Events = lazy(() => import("./events"));
const About = lazy(() => import('./about'));
const Industries = lazy(() => import("./industries"));
const Blog = lazy(() => import('./blog'));
const BlogDetail = lazy(() => import('./blog-detail'));
const ServiceDetail1 = lazy(() => import('./service-detail1'));
const ServiceDetail2 = lazy(() => import('./service-detail2'));
const ServiceDetail3 = lazy(() => import('./service-detail3'));
const ServiceDetail4 = lazy(() => import('./service-detail4'));
const ServiceDetail5 = lazy(() => import('./service-detail5'));
const ServiceDetail6 = lazy(() => import('./service-detail6'));
const Services = lazy(() => import("./services"));
const Contact = lazy(() => import('./contact'));
const EventGallery = lazy(() => import("./event-gallery"));
const Projects = lazy(() => import("./projects"));
const ProjectDetail = lazy(() => import("./project-detail"));

export default {
    Home,
    About,
    Events,
    EventGallery,
    Industries,
    Blog,
    BlogDetail,
    Contact,
    ServiceDetail1,
    ServiceDetail2,
    Services,
    ServiceDetail3,
    ServiceDetail4,
    ServiceDetail5,
    ServiceDetail6,
    Projects,
    ProjectDetail
};