import React, { Fragment } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import screens from "../screens";
import { Block404 } from "../components";

const Routes = () => (
  <Fragment>
    <Switch>
      <Route path={RoutePaths["CONTACTUS"]} component={screens.Contact} />
      <Route path={RoutePaths["EVENTS"]} component={screens.Events} />
      {/*<Route path="/event-gallery" component={screens.EventGallery} />*/}
      <Route path={RoutePaths["INDUSTRIES"]} component={screens.Industries} />
      <Route path={RoutePaths["ABOUTUS"]} component={screens.About} />
      <Route
        path={RoutePaths["BLOGS"] + "/:slug"}
        component={screens.BlogDetail}
      />
      <Route path={RoutePaths["BLOGS"]} component={screens.Blog} />
      <Route
        path={RoutePaths["SERVICES/1"]}
        component={screens.ServiceDetail1}
      />
      <Route
        path={RoutePaths["SERVICES/2"]}
        component={screens.ServiceDetail2}
      />
      <Route
        path={RoutePaths["SERVICES/3"]}
        component={screens.ServiceDetail3}
      />
      <Route
        path={RoutePaths["SERVICES/4"]}
        component={screens.ServiceDetail4}
      />
      <Route
        path={RoutePaths["SERVICES/5"]}
        component={screens.ServiceDetail5}
      />
      <Route
        path={RoutePaths["SERVICES/6"]}
        component={screens.ServiceDetail6}
      />
      <Route path={RoutePaths["SERVICES"]} component={screens.Services} />
      {/*<Route path={RoutePaths["PROJECTS"] + "/:slug"} component={screens.ProjectDetail} />*/}
      <Route path={RoutePaths["PROJECTS"]} component={screens.Projects} />
      <Route path="/" exact component={screens.Home} />

      {oldSeoLinkMap.map((item, index) => (
        <Route
          path={item.old}
          exact
          render={() => <Redirect to={item.new} />}
          key={index}
        />
      ))}

      <Route component={Block404} />
    </Switch>
  </Fragment>
);

export const RoutePaths = {
  BLOGS: "/blogs",
  PROJECTS: "/projects",
  ABOUTUS: "/about",
  CONTACTUS: "/contact",
  EVENTS: "/events",
  SERVICES: "/services",
  INDUSTRIES: "/industries",
};

RoutePaths["SERVICES/1"] = RoutePaths["SERVICES"] + "/3d-rendering-service-1";
RoutePaths["SERVICES/2"] = RoutePaths["SERVICES"] + "/millwork-shop-drawing-2";
RoutePaths["SERVICES/3"] = RoutePaths["SERVICES"] + "/commercial-millwork-3";
RoutePaths["SERVICES/4"] = RoutePaths["SERVICES"] + "/3d-modeling-4";
RoutePaths["SERVICES/5"] =
  RoutePaths["SERVICES"] + "/millwork-engineering-solution-5";
RoutePaths["SERVICES/6"] = RoutePaths["SERVICES"] + "/complete-cnc-support-6";

const oldSeoLinkMap = [
  {
    old: "/3d-rendered-models.html",
    new: RoutePaths["SERVICES/1"],
  },
  {
    old: "/millwork-shop-drawings-submittal.html",
    new: RoutePaths["SERVICES/2"],
  },
  {
    old: "/custom-commercial-drawing.html",
    new: RoutePaths["SERVICES/3"],
  },
  {
    old: "/3d-modeling.html",
    new: RoutePaths["SERVICES/4"],
  },
  {
    old: "/millwork-engineering-solutions.html",
    new: RoutePaths["SERVICES/5"],
  },
  {
    old: "/complete-cnc-support.html",
    new: RoutePaths["SERVICES/6"],
  },
  {
    old: "/about.html",
    new: "/about",
  },
  {
    old: "/about-us",
    new: "/about",
  },
  {
    old: "/vision-mission-values.html",
    new: "/about#our-approach",
  },
  {
    old: "/our-team.html",
    new: "/about#our-team",
  },
  {
    old: "/our-commitment.html",
    new: "/",
  },
  {
    old: "/enquiry.html",
    new: "/#enquiry",
  },
  {
    old: "/case-studies.html",
    new: "/",
  },
  {
    old: "/faqs.html",
    new: "/contact#faq",
  },
  {
    old: "/testimonials.html",
    new: "/#testimonials",
  },
  {
    old: "/projects.html",
    new: "/projects",
  },
  {
    old: "/contact.html",
    new: "/contact",
  },
  {
    old: "/industries-we-serve.html",
    new: RoutePaths["INDUSTRIES"],
  },
  {
    old: "/index.html",
    new: "/",
  },
];

export default Routes;
