import React, { Component } from "react";
import { withRouter } from "react-router-dom";
//import { Helmet } from "react-helmet";
import getMetaData from "../commands/getMetaData";
import { HelmetProvider } from "react-helmet-async";
import { Helmet } from "react-helmet-async";

const commands = {
  getMetaData,
};

class SiteSeo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      description: "",
      showTags: true,
    };

    this.fetchMetaData = this.fetchMetaData.bind(this);
  }

  componentDidMount() {
    this.fetchMetaData({ location: { pathname: "" } });
  }

  componentDidUpdate(prevProps) {
    this.fetchMetaData(prevProps);
  }

  fetchMetaData(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      var pathname = this.props.location.pathname;
      var url = undefined;
      var blogId = undefined;
      if (pathname.startsWith("/blogs/") && pathname.includes("-")) {
        blogId = pathname.split("-").pop();
      } else {
        url = pathname;
      }

      commands.getMetaData({ url, blogId }).then((data) => {
        if (data?.results?.length > 0) {
          const meta = data.results[0];
          this.setState({
            title: meta.title,
            description: meta.description,
            showTags: false,
          });
          setTimeout(() => {
            this.setState({ showTags: true });
          }, 300);
        } else {
          this.setState({ title: "", description: "" });
        }
      });
    }
  }
  render() {
    return (
      this.state.showTags && (
        <HelmetProvider>
          <Helmet defer={false}>
            <title>
              {this.state.title ||
                "A2Z Millwork Design LLC: Millwork Solutions | Services for drafting"}
            </title>
            <meta
              name="description"
              content={
                this.state.description ||
                "A2Z Millwork Company is furnished great millwork administrations went with savvy answers for our customers. We offer custom architectural millwork, modelling & drafting overall administrations."
              }
            />
          </Helmet>
        </HelmetProvider>
      )
    );
  }
}

export default withRouter(SiteSeo);
