import { lazy } from "react";
import Header from "./header";
import Footer from "./footer";
import SiteSeo from "./site-seo";
import ScrollToTop from "./scroll-to-top";

const QuoteBlock = lazy(() => import("./quote-block"));
const ClientBlock = lazy(() => import("./client-block"));
const SpeakBlock = lazy(() => import("./speak-block"));
const AboutBlock = lazy(() => import("./about-block"));
const ServicesBlock = lazy(() => import("./services-block"));
const HeadingBlock = lazy(() => import("./heading-block"));
const HeadingBlock2 = lazy(() => import("./heading-block2"));
const AboutSkillsBlock = lazy(() => import("./about-skills-block"));
const SliderBlock = lazy(() => import("./slider-block"));
const CompletedWorksBlock = lazy(() => import("./completed-works-block"));
const CompletedWorksBlock2 = lazy(() => import("./completed-works-block/2"));
const CompletedWorksBlockCustom = lazy(() =>
  import("./completed-works-block-custom")
);
const ImageTitleBlock = lazy(() => import("./image-title-block"));
const EventListBlock = lazy(() => import("./events-list-block"));
const IndustryListBlock = lazy(() => import("./industry-list-block"));

const AboutTeamBlock = lazy(() => import("./about-team-block"));
const BlogItemBlock = lazy(() => import("./blog-item-block"));
const BlogItemDetail = lazy(() => import("./blog-detail"));
const ServiceItemDetail1 = lazy(() => import("./service-detail-1"));
const ServiceItemDetail2 = lazy(() => import("./service-detail-2"));
const ServiceItemDetail3 = lazy(() => import("./service-detail-3"));
const ServiceItemDetail4 = lazy(() => import("./service-detail-4"));
const ServiceItemDetail5 = lazy(() => import("./service-detail-5"));
const ServiceItemDetail6 = lazy(() => import("./service-detail-6"));
const ServiceListBlock = lazy(() => import("./service-list-block"));
const ContactBlock = lazy(() => import("./contact"));
const EventPhotoGalleryBlock = lazy(() =>
  import("./event-photo-gallery-block")
);
const ProjectsGridBlock = lazy(() => import("./projects-grid-block"));
const ProjectItemDetail = lazy(() => import("./project-detail"));
const Block404 = lazy(() => import("./404-block"));
const BusinessPartnerBlock = lazy(() => import("./business-partner-block"));
const WhyChooseUsBlock = lazy(() => import("./why-choose-us-block"));
const LoaderOverlay = lazy(() => import("./loader-overlay"));
const EventGallery2 = lazy(() => import("./events-gallery-block-v2"));
const ErrorBlock = lazy(() => import("./error-block"));
const ProjectGallery = lazy(() => import("./project-gallery-block"));
const VideoBlock = lazy(() => import("./video-block"));
const FaqBlock = lazy(() => import("./faq-block"));
const ScrollToHash = lazy(() => import("./scroll-to-hash"));
const Image = lazy(() => import("./image"));
const MainPopup = lazy(() => import("./main-popup"));

export {
  ScrollToTop,
  Header,
  Footer,
  QuoteBlock,
  ClientBlock,
  SpeakBlock,
  AboutBlock,
  ServicesBlock,
  HeadingBlock,
  AboutSkillsBlock,
  SliderBlock,
  CompletedWorksBlock,
  CompletedWorksBlock2,
  ImageTitleBlock,
  EventListBlock,
  IndustryListBlock,
  AboutTeamBlock,
  BlogItemBlock,
  BlogItemDetail,
  ContactBlock,
  ServiceItemDetail1,
  ServiceItemDetail2,
  ServiceListBlock,
  ServiceItemDetail3,
  ServiceItemDetail4,
  ServiceItemDetail5,
  ServiceItemDetail6,
  EventPhotoGalleryBlock,
  ProjectsGridBlock,
  HeadingBlock2,
  ProjectItemDetail,
  Block404,
  BusinessPartnerBlock,
  WhyChooseUsBlock,
  CompletedWorksBlockCustom,
  LoaderOverlay,
  EventGallery2,
  ErrorBlock,
  ProjectGallery,
  VideoBlock,
  FaqBlock,
  SiteSeo,
  ScrollToHash,
  Image,
  MainPopup
};
