import React, { useState, useEffect } from "react";
import Routes from "./routes";
import { useToasts } from "react-toast-notifications";
//import { useSelector } from "react-redux";
//import CacheState from "./redux/states/cache";
//import ConfigState from "./redux/states/config";
import Config from "./config";
import { BrowserRouter as Router } from "react-router-dom";
import ScrollToTop from "./components/scroll-to-top";
import SiteSeo from "./components/site-seo";
import Header from "./components/header";
import Footer from "./components/footer";
import LoaderOverlay from "./components/loader-overlay";
//import MainPopup from "./components/main-popup";

function App() {
  //dont remove it
  //const store = useSelector((store) => store);
  // const cacheState = CacheState.get();
  //const configState = ConfigState.get();
  const [popup, setPopup] = useState(false);

  function showPopup() {
    setPopup(true);
  }

  function onClosePopup() {
    setPopup(false);
  }

  useEffect(() => {
    setTimeout(() => showPopup(), 3000);
  }, []);

  const { addToast } = useToasts();
  if (!window.notify) {
    window.notify = {
      success: (message) => addToast(message, { appearance: "success" }),
      error: (message) => {
        if (Config.environment === "test") {
          addToast(message, { appearance: "error" });
        }
      },
      warning: (message) => addToast(message, { appearance: "warning" }),
      info: (message) => addToast(message, { appearance: "info" }),
    };
  }

  /*
  if (!configState.routes) {
    ConfigState.set({
      routes: RoutePaths
    });
  }*/

  return window.notify /*&& configState.routes*/ ? (
    <Router>
      {/*<MainPopup
        openState={[popup, setPopup]}
        onClose={onClosePopup}
        path={"/contact"}
      />*/}
      <ScrollToTop />
      <SiteSeo />
      <Header />
      <Routes />
      <Footer />
    </Router>
  ) : (
    <LoaderOverlay isOpen={true} />
  );
}

export default App;
