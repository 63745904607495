import React, { useState, useRef } from "react";
import { Row, Col, Nav, NavLink, NavItem, Collapse } from "reactstrap";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";
import "./index.scoped.css";
import { useMediaQuery } from 'react-responsive';
import classnames from "classnames";
import { Link } from "react-router-dom";
import LazyLoad from 'react-lazyload';
import { RoutePaths } from "../../routes";


export default function Footer() {
    const [openedMenu, setOpenedMenu] = useState("1");
    const isNotMobile = useMediaQuery({
        query: '(min-device-width: 767.88px)'
    });
    const isOpen = (id) => isNotMobile || openedMenu === id;
    const toggle = (id) => isOpen(id) ? setOpenedMenu("") : setOpenedMenu(id);


    const menuRef = useRef();
    const copyRef = useRef();

    return (
        <div className="x-footer" >
            <div className="x-footer-item-container">
                <Row className="x-links-container" >
                    <Col xs={12} md={4} className="x-company-container">
                        <LazyLoad>
                            <img src={require("./logo.svg").default} alt="a2zmillwork" className="x-logo" ref={menuRef} />
                        </LazyLoad>
                        <div className="x-logo-text">
                            We are specialize in providing submittal shop drawing in custom woodwork, Commercial and residential areas by maintaining quality based on AWI & AWMAC standards.
                        </div>
                        <div>
                            <a href="https://www.facebook.com/A2Z-Millwork-Design-LLC-109359160469192/" target="_blank"><img src={require("./facebook-icon.svg").default} alt="facebook" className="x-social-icons" /></a>
                            <a href="https://www.linkedin.com/company/a2z-millwork/" target="_blank"><img src={require("./linkedin-icon.svg").default} alt="linkedin" className="x-social-icons" /></a>
                            <a href="https://twitter.com/a2zmillwork/" target="_blank"><img src={require("./twitter-icon.svg").default} alt="twitter" className="x-social-icons" /></a>
                            <a href="https://www.instagram.com/a2zmillwork_/" target="_blank"><img src={require("./instagram-icon.svg").default} alt="instagram" className="x-social-icons" /></a>
                            <a href="https://www.youtube.com/channel/UC0NG7b4v5wX9Y1-ANR106xg/featured" target="_blank"><img src={require("./youtube-icon.svg").default} alt="youtube" className="x-social-icons" /></a>
                        </div>
                    </Col>
                    <Col xs={12} md={4} >
                        <Row >
                            <Col xs={12} md={6}>
                                <Nav vertical>
                                    <NavItem className="x-links-title" onClick={() => toggle("1")}>
                                        About {isOpen("1") ? <RiArrowUpSLine className="x-down-arrow" /> : <RiArrowDownSLine className="x-down-arrow" />}
                                    </NavItem>
                                    <Collapse isOpen={isOpen("1")}>
                                        <NavItem>
                                            <Link className="x-links nav-link" to="/about#our-skills"  >
                                                Our Skills
                                            </Link>
                                        </NavItem>
                                        <NavItem>
                                            <Link className="x-links nav-link" to="/about#our-approach"  >
                                                Our Approach
                                            </Link>
                                        </NavItem>
                                        <NavItem>
                                            <Link className="x-links nav-link" to="/about#why-choose-us">
                                                Why Choose Us
                                            </Link>
                                        </NavItem>
                                        <NavItem>
                                            <Link className="x-links nav-link" to="/about#our-team">
                                                Our Team
                                            </Link>
                                        </NavItem>
                                    </Collapse>
                                </Nav>
                            </Col>
                            <Col xs={12} md={6} className="x-services-col">
                                <Nav vertical>
                                    <NavItem className="x-links-title" onClick={() => toggle("2")}>
                                        Services {isOpen("2") ? <RiArrowUpSLine className="x-down-arrow" /> : <RiArrowDownSLine className="x-down-arrow" />}
                                    </NavItem>
                                    <Collapse isOpen={isOpen("2")}>
                                        <NavItem>
                                            <NavLink className="x-links" href={RoutePaths["SERVICES/2"]}>
                                                Millwork drawing
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink className="x-links" href={RoutePaths["SERVICES/4"]}>
                                                3d modeling
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink className="x-links" href={RoutePaths["SERVICES/3"]}>
                                                Custom drawing
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink className="x-links" href={RoutePaths["SERVICES/1"]}>
                                                3d rendered Models
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink className="x-links" href={RoutePaths["SERVICES/5"]}>
                                                Engineering solutions
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink className="x-links" href={RoutePaths["SERVICES/6"]}>
                                                CNC supports
                                            </NavLink>
                                        </NavItem>
                                    </Collapse>
                                </Nav>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} md={4} >
                        <Row>
                            <Col xs={12} md={6} className="x-support-col">
                                <Nav vertical>
                                    <NavItem className="x-links-title" onClick={() => toggle("3")}>
                                        Support {isOpen("3") ? <RiArrowUpSLine className="x-down-arrow" /> : <RiArrowDownSLine className="x-down-arrow" />}
                                    </NavItem>
                                    <Collapse isOpen={isOpen("3")}>
                                        <NavItem>
                                            <NavLink className="x-links" href="/files/privacy-policy.pdf" target="_blank">
                                                Privacy Policy
                                            </NavLink>
                                        </NavItem>
                                    </Collapse>
                                </Nav>
                            </Col>
                            <Col xs={12} md={6} className="x-contactus-col">
                                <Nav vertical>
                                    <NavItem className="x-links-title" onClick={() => toggle("4")}>
                                        Contact Us {isOpen("4") ? <RiArrowUpSLine className="x-down-arrow" /> : <RiArrowDownSLine className="x-down-arrow" />}
                                    </NavItem>
                                    <Collapse isOpen={isOpen("4")}>
                                        <div className="x-text">
                                            A2Z Millwork Design LLC, 304 S.Jones Blvd #2462 Las Vegas, NV 89107, USA
                                        </div>
                                        <div className="x-text">
                                            A2Z Millwork Design LLC, 797 Campbell Ave,  Windsor, Ontario, N9B 2H6, Canada
                                        </div>
                                        <div className="x-text">
                                            +1 (716) 3092180
                                        </div>
                                        <div className="x-text">
                                            info@a2zmillwork.com
                                        </div>
                                    </Collapse>
                                </Nav>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <div className={classnames("x-copywrite ")} ref={copyRef}>
                    Copyright {new Date().getFullYear()} website by <a target="_blank" href="https://a2zwebsolution.com" className="x-terms">A2Z Websolution LLC</a>. All Rights Reserved. <a className="x-terms" href="/files/terms-of-service.pdf" target="_blank">Terms of use</a>
                </div>
            </div>
        </div>
    )
}