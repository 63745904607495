import React, { Suspense, Fragment } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import store, { persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import * as serviceWorker from "./serviceWorker";
import "bootstrap/dist/css/bootstrap.min.css";
import "animate.css/animate.css";
import "aos/dist/aos.css";
import AOS from "aos";
import "./css/index.css";
import "./css/variables.css";
import "./css/paginator.css";
import { ToastProvider } from "react-toast-notifications";

window.addEventListener("load", function() {
  AOS.init();
});

const Loader = (
  <Fragment>
    <style>
      {`.x-load-block {
        position: fixed;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1099;
      }

      .x-load-overlay {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #fff;
        opacity: 1;
      }

      .x-load-loading-image {
        height: 3.7064492vw;
        width: 3.7064492vw;
        z-index: 1;
      }

      @media (max-width: 1200px) {

        .x-load-loading-image {
          height: 9.1145833333vw;
          width: 9.1145833333vw;
        }
      }

      @media (max-width: 767.88px) {

        .x-load-loading-image {
          height: 12.077294685vw;
          width: 12.077294685vw;
        }
      }`}
    </style>
    <div className="x-load-block">
      <img
        src="/images/loading.gif"
        alt="Loading Please Wait..."
        className="x-load-loading-image"
      />
      <div className="x-load-overlay" />
    </div>
  </Fragment>
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={Loader} persistor={persistor}>
        <ToastProvider autoDismissTimeout={10000} autoDismiss={true}>
          <Suspense fallback={Loader}>
            <App />
          </Suspense>
        </ToastProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
