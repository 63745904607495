import service, { serviceTypes } from "../services";

export default async function getMetaData({ url = "", blogId = "" }) {

    const serviceType = serviceTypes.getMetaData();

    console.log("params ",url+blogId);
    if (url) {
        serviceType.params.url = url;
    } else if (blogId) {
        serviceType.params.blog_id = blogId;
    }


    const { response, error } = await service(serviceType);

    if (error) {
        //window.notify.error(error.message);
    } else if (Array.isArray(response.data?.results) && "total" in response.data) {
        return response.data;
    }

    return { total: 0, results: [] };

}