import React from "react";
import "./index.scoped.css"
export default function LoaderOverlay(props) {
    if (!props.isOpen) return null;
    return (
        <div className="x-block">
            <img src={"/images/loading.gif"} alt="Loading Please Wait" className="x-loading-image" />
            <div className="x-overlay">
            </div>
        </div>
    );
}