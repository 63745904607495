import React, { useEffect, useState, Fragment } from "react";
import "./index.scoped.css";
import { Nav } from 'reactstrap';
import { useHistory, Link, NavLink } from "react-router-dom"
import { RoutePaths } from "../../routes";
import classnames from "classnames";

export default function Header() {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(prev => !prev);
    const history = useHistory();
    const currentPath = history.location.pathname;
    useEffect(() => {
        console.log("current pathname ", currentPath);
    }, [currentPath]);

    const logoImage= "/images/logo.svg" || require("./logo.svg").default;

    return (
        <Fragment>
            <div className={classnames("x-header", { "x-closed": !isOpen })}>
                <div className="x-topnav-wrapper">
                    <Link to="/">
                        <img src={logoImage} alt="a2zmillwork" className="x-logo" onClick={() => setIsOpen(false)} />
                    </Link>
                    {isOpen ?
                        <img src={require("./menu-close-icon.png")} alt="menu" className="x-menu-close-icon" onClick={toggle} />
                        :
                        <img src={require("./menu-icon.png")} alt="menu" className="x-menu-icon" onClick={toggle} />
                    }
                </div>
                <Nav>
                    <NavLink to="/" activeClassName="x-active" className="nav-link animation" exact onClick={() => setIsOpen(false)}>
                        Home
                    </NavLink>
                    <NavLink to="/about" activeClassName="x-active" className="nav-link" onClick={() => setIsOpen(false)}>
                        About
                    </NavLink>
                    <NavLink to="/services" activeClassName="x-active" className="nav-link" onClick={() => setIsOpen(false)}>
                        Services
                    </NavLink>
                    <NavLink to="/projects" activeClassName="x-active" className="nav-link" onClick={() => setIsOpen(false)}>
                        Projects
                    </NavLink>
                    <NavLink to={RoutePaths["BLOGS"]} activeClassName="x-active" className="nav-link" onClick={() => setIsOpen(false)}>
                        Blog
                    </NavLink>
                    <NavLink to="/industries" activeClassName="x-active" className="nav-link" onClick={() => setIsOpen(false)}>
                        Industries
                    </NavLink>
                    <NavLink to="/events" activeClassName="x-active" className="nav-link" onClick={() => setIsOpen(false)}>
                        Events
                    </NavLink>
                </Nav>
                <Link to="/contact" className="nav-link x-contactus">
                    <span className="btn btn-primary x-nav-btn btn-sm d-flex align-items-center" onClick={() => setIsOpen(false)}>Contact Us</span>
                </Link>
            </div>
            <div className="x-header-spacing" />
        </Fragment>
    )
}